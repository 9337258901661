import React from 'react';
import Completed from '@/components/organisms/Inquiry/CommonComponent/Completed';
import { Routings } from '@/common/routings';
import SellerHeader from '@/components/atoms/Header/SellerHeader';
import SellerFooter from '@/components/atoms/Footer/SellerFooter';
import { Wrapper } from '@/components/layout/common';
import { useDirectAccess } from '@/common/hooks';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

const CompletedPage = () => {
  const isDirectAccess = useDirectAccess(Routings.sellerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <Wrapper>
      <SellerHeader displayPattern="logo" />
      <Completed top={Routings.sellerTop.location} />
      <SellerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(CompletedPage);
